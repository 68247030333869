import ApplicationController from './application_controller'

export default class extends ApplicationController {
    connect() {
		let conv_id = this.element.dataset.conversationId
		otty.subscribeToPoll(
			['UserChannel', 'ConversationUpdateChannel'],
			{'conversation-id': conv_id },
			1500,
			'/api/poll',
			'/api/pollsub'
		)
    }
	disconnect() {
		otty.subscribeToPoll(['UserChannel'], {}, 5000, '/api/poll', '/api/pollsub')
	}
}